const React = require('react')
const PropTypes = require('prop-types')
const styles = require('./profile.css')
const UserNote = require('../../components/profile/user-note')
function Links(props) {
  const {
    scope: {parent, type},
  } = props
  const {resource = {}} = props.scope
  const name = parent.name
  const isCurrentUser = props.scope.isCurrentUser
  const accountLinkFeatureOn = props.scope.isAccountLinkEnabledForUser
  const {fullname, github, twitter, isGithubVerified, isTwitterVerified, githubLegacy, twitterLegacy} = resource

  // current user can see its own handles even if they are legacy (not actually linked to
  // the corresponding account - twitter or github).
  // However, user's should only see other user's handles if they are linked to their respective account
  let viewTwitterAccountLink
  if (accountLinkFeatureOn) {
    viewTwitterAccountLink = (isCurrentUser && twitter) || (isTwitterVerified && twitter)
  } else {
    viewTwitterAccountLink = twitter
  }

  let viewGithubAccountLink
  if (accountLinkFeatureOn) {
    viewGithubAccountLink = (isCurrentUser && github) || (isGithubVerified && github)
  } else {
    viewGithubAccountLink = github
  }

  const viewAccountLinks = viewTwitterAccountLink || viewGithubAccountLink
  let githubStatus = ''
  let twitterStatus = ''
  const shouldUserNoteVisible = !(isGithubVerified && isTwitterVerified)

  if (accountLinkFeatureOn && isCurrentUser) {
    githubStatus = githubLegacy && !isGithubVerified ? ' (not linked)' : ''
    twitterStatus = twitterLegacy && !isTwitterVerified ? ' (not linked)' : ''
  }

  return (
    <div>
      <h1 className={styles.headingTwo}>{name}</h1>
      {type === 'user' && fullname && (
        <div>
          <hr className={styles.divider} />
          <div className={styles.fullname}>{fullname}</div>
        </div>
      )}
      {type === 'user' && viewAccountLinks ? (
        <ul className={styles.linksList}>
          {viewGithubAccountLink && (
            <li className={styles.linksListItem}>
              <span className={styles.github} />
              <a rel="nofollow" href={`https://github.com/${github}`} className={styles.link}>
                {`@${github}`}
                {`${githubStatus}`}
              </a>
            </li>
          )}
          {viewTwitterAccountLink && (
            <li className={styles.linksListItem}>
              <span className={styles.twitter} />
              <a rel="nofollow" href={`https://twitter.com/${twitter}`} className={styles.link}>
                {`@${twitter}`}
                {`${twitterStatus}`}
              </a>
            </li>
          )}
          {accountLinkFeatureOn && isCurrentUser && shouldUserNoteVisible && <UserNote userName={name} />}
        </ul>
      ) : (
        <hr className={styles.divider} />
      )}
    </div>
  )
}

Links.propTypes = {
  scope: PropTypes.shape({
    parent: PropTypes.shape({
      name: PropTypes.string.isRequired,
      resource: PropTypes.object,
    }).isRequired,
  }).isRequired,
}

module.exports = Links
